import axios from 'axios';
import authService from '@/services/auth.service';
import router from '@/router';
import { sharedModule } from '@/store/modules/shared';
import { accountModule } from '@/store/modules/account';

export const HTTPS = axios.create({
    baseURL: `${process.env.VUE_APP_API}`,
    headers: {}
});

// Add a request interceptor
HTTPS.interceptors.request.use((config) => {
    if (config.headers) {
        config.headers.Authorization = `Bearer ${authService.getAccessToken()}`;
    }
    return config;
});

HTTPS.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const errorStatuses = [400, 403, 404];
    if (errorStatuses.includes(error.response.status)) {
        const exceptions = ['Resource \'There is no submitted requests!\' not found!', 'Resource \'There is no requests!\' not found!', 'Resource \'calendar\' not found!', 'Resource \'calendars\' not found!'];
        if (exceptions.includes(error.response.data)) {
            console.log('No requests');
        } else {
            sharedModule.showSnackbar({
                text: error.response.data.title ? error.response.data.title : error.response.data,
                color: 'error',
                timeout: 5000,
            });
        }
    } else if (error.response.status === 401) {
        accountModule.logout();
        if (router.currentRoute.name !== 'Login' && router.currentRoute.name !== 'Home') {
            router.replace({
                path: '/login',
                query: { redirect: router.currentRoute.fullPath }
            });
        } else if (router.currentRoute.name !== 'Login') {
            router.push('/login');
        }
        sharedModule.showSnackbar({
            text: 'You are not authorized! Please sign in again.',
            color: 'error',
            timeout: 5000,
        });
    } else if (error.response.status === 500) {
        sharedModule.showSnackbar({
            text: error.response.data,
            color: 'error',
            timeout: 5000,
        });
    }
    return Promise.reject(error);
});
