import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import UserLayoutView from '../views/UserLayout.vue';
import NotFoundView from '../views/404.vue';
import authenticate from '@/guards/authenticate';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/login',
        component: LoginView,
        name: 'Login'
    },
    {
        path: '/',
        component: UserLayoutView,
        children: [
            {
                path: 'requests/:id',
                name: 'Request',
                meta: { requiresAuth: true },
                component: () => import(/* webpackChunkName: "request" */ '../views/RequestView.vue')
            },
            {
                path: 'requests',
                name: 'Requests',
                meta: { requiresAuth: true },
                component: () => import(/* webpackChunkName: "requests" */ '../views/RequestsView.vue')
            },
            {
                path: '',
                name: 'Home',
                meta: { requiresAuth: true },
                component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
                children: [
                    {
                        path: 'new',
                        name: 'NewRequest',
                    },
                    {
                        path: 'new/:date',
                        name: 'NewRequest',
                    },
                    {
                        path: 'new/:start/:end',
                        name: 'NewRequest',
                    },
                ]
            },
            {
                path: 'hr',
                name: 'HR',
                meta: { requiresAuth: true },
                component: () => import(/* webpackChunkName: "hr" */ '../views/HrView.vue'),
                children: [
                    {
                        path: 'correction/:name/:id',
                        name: 'Correction',
                    },
                ]
            },
            {
                path: 'holiday-calendar',
                name: 'HolidayCalendar',
                meta: { requiresAuth: true },
                component: () => import(/* webpackChunkName: "calendar" */ '../views/HolidayCalendarView.vue')
            }
        ]
    },
    {
        // not found handler
        path: '*',
        component: NotFoundView,
        name: 'NotFound'
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    authenticate(to, from, next);
});

export default router;
