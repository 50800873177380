

import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import SnackbarComponent from '@/components/shared/SnackbarComponent.vue';

@Component({
  components: {
    SnackbarComponent
  }
})
export default class App extends Vue {
  backgroundColors = {
    'Request': '#F2F2E9',
    'Home': '#F2F2E9',
    'NewRequest': '#F2F2E9',
    'HolidayCalendar': '#F2F2E9', 
    'Requests': '#F2F2E9'
  };
}
