import authService from '@/services/auth.service';
import { NavigationGuardNext, Route } from 'vue-router';

export default (to: Route, from: Route, next: NavigationGuardNext) => {
    if (to.meta?.requiresAuth) {
        if (authService.getAccessToken()) {
            next();
        } else if (to.name !== 'Home') {
            next({ name: 'Login', query: { redirect: to.fullPath } });
        } else {
            next({ name: 'Login' });
        }
    }
    return next();
}
