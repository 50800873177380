
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import store from '@/store';

@Component({})
export default class SnackbarComponent extends Vue {

    text = '';
    color = '';
    timeout = 0;
    show = false;

    created() {
        store.subscribe((mutation, state) => {
            if (mutation.type === 'showSnackbar') {
                this.text = state.shared.text;
                this.color = state.shared.color;
                this.timeout = state.shared.timeout;
                this.show = true;
            }
        });
    }
}
