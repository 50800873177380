import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { AbsenceTypeWrapper } from '@/types/types';

export interface SharedModuleState {
    text: string,
    color: string,
    timeout: number,
    requestTypes: AbsenceTypeWrapper[],
    manualBonusRequestTypes: AbsenceTypeWrapper[]
}

@Module({ dynamic: true, store: store, name: 'shared' })
export default class SharedModule extends VuexModule implements SharedModuleState {
    text = '';
    color = '';
    timeout = 0;
    requestTypes: AbsenceTypeWrapper[] = [];
    manualBonusRequestTypes: AbsenceTypeWrapper[] = [];

    @Mutation
    showSnackbar({ text, color, timeout }: Partial<{ text: string, color: string, timeout: number }>) {
        // TODO myb check if undefined and use old value instead of setting them to default values
        this.text = text ?? '';
        this.color = color ?? '';
        this.timeout = timeout ?? 0;
    }

    @Mutation
    setRequestTypes(types: AbsenceTypeWrapper[]) {
        this.requestTypes = types;
    }

    @Mutation
    setManualBonusRequestTypes(types: AbsenceTypeWrapper[]) {
        this.manualBonusRequestTypes = types;
    }
}

export const sharedModule = getModule(SharedModule);
