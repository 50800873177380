
import vueCustomScrollbar from 'vue-custom-scrollbar';
import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { accountModule } from '@/store/modules/account';

@Component({
  components: {
    vueCustomScrollbar
  }
})
export default class SidenavComponent extends Vue {
  matchQuery = window.matchMedia("(max-width: 959px)");
  mini = window.matchMedia("(max-width: 959px)").matches;
  isMobile = window.matchMedia("(max-width: 959px)").matches;

  mounted() {
    this.matchQuery.addListener(() => {
      this.mini = this.matchQuery.matches
      this.isMobile = this.matchQuery.matches
    });
  }

  hideDrawer() {
    if (this.isMobile) {
      this.mini = true;
    }
  }

  openDrawer() {
    if (this.isMobile) {
      this.mini = false;
    }
  }

  toggleMini() {
    this.mini = !this.mini;
  }

  settings = {
    suppressScrollY: false,
    suppressScrollX: true,
    wheelPropagation: false,
  };

  get user() {
    return accountModule.account;
  }

  get userPicture() {
    return accountModule.picture;
  }

  get isEmployee() {
    return accountModule.isEmployee;
  }

  get isApprover() {
    return accountModule.isApprover;
  }

  get isAdmin() {
    return accountModule.isAdmin;
  }

  logout() {
    accountModule.logout();
    this.$router.push('/login');
  }
}
