

import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import authService from '@/services/auth.service';
import { accountModule } from '@/store/modules/account';
import router from '@/router';
import CredentialResponse = google.accounts.id.CredentialResponse;

@Component({})
export default class LoginView extends Vue {
  clientId = '961451749671-jsgn9e52jo30q631ekhc57ql5mle1s9t.apps.googleusercontent.com';
  errorMessage = '';

  handleCredentialResponse(response: CredentialResponse) {
    authService.setAccessToken(response.credential);
    this.getLoginDetails();
  }

  renderGoogleButton() {
    const googleScript = document.createElement('script');
    googleScript.src = 'https://accounts.google.com/gsi/client';
    document.head.appendChild(googleScript);
    window.onGoogleLibraryLoad = () => {
      window.google.accounts.id.initialize({
        // eslint-disable-next-line camelcase
        client_id: this.clientId,
        callback: this.handleCredentialResponse
      });
      const signInButton = document.getElementById('signin_button');

      if (signInButton) {
        window.google.accounts.id.renderButton(
          signInButton,
          { type: 'standard', theme: 'outline', size: 'medium' }
        );
      }
    };
  }

  getLoginDetails() {
    accountModule.getAccount().then(response => {
      if (response.status === 200) {
        if (this.$route.query.redirect) {
          router.push(`${this.$route.query.redirect}`);
        } else {
          router.push('/');
        }
      }
    }).catch(error => {
      this.errorMessage = error;
    });
  }

  mounted() {
    if (!authService.getAccessToken()) {
      this.renderGoogleButton();
    } else {
      this.getLoginDetails();
    }
  }
}

