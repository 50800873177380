import { HTTPS } from '@/services/axios.config';
import authService from '@/services/auth.service';
import { Account } from '@/entities/Account';
import { AvailableDays } from '@/entities/Account';
import { AMSResponse } from '@/types/types';

export class AccountService {
    static async getAccount() {
        return await HTTPS.get<AMSResponse<Account>>('/account');
    }

    static getUserPicture(): string {
        const gAccount = authService.parseToken();
        return gAccount !== null ? gAccount.picture : '';
    }

    static async getAvailableDays() {
        return await HTTPS.get<AMSResponse<AvailableDays>>('/account/available-days');
    }
}
