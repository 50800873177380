import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'bootstrap/dist/css/bootstrap.css';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.config.productionTip = false;

Vue.use(VueLoading, {
    color: '#001248',
    loader: 'spinner',
    width: 100,
    height: 100,
    backgroundColor: '#001248',
    opacity: 0.2,
    fullPage: false,
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
