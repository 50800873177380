import Vue from 'vue';
import Vuex from 'vuex';
import { AccountModuleState } from '@/store/modules/account';
import { SharedModuleState } from '@/store/modules/shared';
import {HolidayCalendarModuleState} from '@/store/modules/holiday-calendar';

Vue.use(Vuex);

interface RootState {
    account: AccountModuleState;
    shared: SharedModuleState;
    holidayCalendar: HolidayCalendarModuleState;
}

const store = new Vuex.Store<RootState>({});

export default store;
