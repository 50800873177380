import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Account } from '@/entities/Account';
import { AccountService } from '@/services/account.service';
import authService from '@/services/auth.service';
import store from '@/store';

export interface AccountModuleState {
    account: Account | null,
    picture: string
}

@Module({ dynamic: true, store: store, name: 'account' })
export default class AccountModule extends VuexModule implements AccountModuleState {
    account: Account | null = null;
    picture = '';

    @Mutation
    setAccount(account: Account) {
        this.account = account;
    }

    @Mutation
    setAccountPicture(picture: string) {
        this.picture = picture;
    }

    @Mutation
    resetState() {
        this.account = null;
        this.picture = '';
    }

    @Action
    async getAccount() {
        const resp = await AccountService.getAccount();
        const account = resp.data.data;
        if (!account.roles.isAuthorized) {
            this.logout();
        } else {
            this.setAccount(account);
            this.getAccountPicture();
        }
        return resp;
    }

    @Action
    async getAvailableDays() {
        const resp = await AccountService.getAvailableDays();
        const days = resp.data.data;
        return days;
    }

    @Action({ commit: 'resetState' })
    logout() {
        authService.removeAccessToken();
    }

    @Action
    getAccountPicture() {
        const picture = AccountService.getUserPicture();
        this.setAccountPicture(picture);
    }

    get user() {
        return this.account;
    }

    get isEmployee() {
        return this.user?.roles.isEmployee;
    }

    get isApprover() {
        return this.user?.roles.isApprover;
    }

    get isAdmin() {
        return this.user?.roles.isAdmin;
    }

    get isAuthorized() {
        return this.isEmployee || this.isApprover || this.isAdmin;
    }
}

export const accountModule = getModule(AccountModule);
