import { GAccountData } from '@/types/types';

class AuthService {
    setAccessToken(token: string) {
        localStorage.setItem('token', token);
    }

    getAccessToken() {
        return localStorage.getItem('token');
    }

    removeAccessToken() {
        localStorage.removeItem('token');
    }

    parseToken(): GAccountData | null {
        const token = this.getAccessToken();
        if (!token) {
            return null;
        }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        }).join(''));
        return JSON.parse(jsonPayload);
    }
}

export default new AuthService();
