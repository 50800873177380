
import SidenavComponent from '../components/SidenavComponent.vue';
import authService from '@/services/auth.service';
import { accountModule } from '@/store/modules/account';
import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  components: { SidenavComponent, },
})
export default class UserLayout extends Vue {

  get account() {
    return accountModule.account;
  }

  created() {
    if (!this.account && authService.getAccessToken()) {
      accountModule.getAccount();
    }
  }
}

